.Carosel-img-container{

    display: flex;
    width: 100%;
    height: 77%;
    background: #000;
}
.Carosel-content{

  position: absolute;
  width: 400px;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%);
  float: left;
}

.Carosel-text{
    text-align: left;
    justify-content: left;
    align-items: flex-start;
    
    color: #fff;
    font-size: 45px;
    font-family: 'Poppins', sans-serif;

}

.Carosel-read-more-btn{
    width: 150px;
    background-color: #1cac5c;
    color: #fff;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    border-color: transparent;
    border-radius: 40px;
    height: 50px;
    
    left: 0;
    float: left;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Carosel-img-container .carosel-img{
    display: block;
    width: 800px;
    height: 80%;
    object-fit: cover;
    opacity: 0.5;
    

    

    
    
}
.carosel-img{
    display: block;
    width: 800px;
    height: 80%;
    object-fit: cover;
    opacity: 0.5;

}

@media screen and (max-width:640px) {

    .Carosel-content{
        width: 120px;
        height: 150px;

    }
    .Carosel-text{
        font-size: 16px;
    }
    .Carosel-read-more-btn{
        width: 80px;
        background-color: #1cac5c;
        color: #fff;
        font-size: 10px;
        height: 30px;
    }
    .Carosel-img-container{
        width: 400px;
        height: 500px;
        position: absolute;
        top: 40%;
        
        

    }
    
    
}
.Sitemap-container{
    display: flex;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    flex-direction: column;
    margin: 5px;
}
.Sitemap-wrapper{
    display: flex;
    width: 100%;
    height: 550px;
    background-color: #232323;
    align-items: center;
    justify-content: space-around;
    flex-flow: row;
    flex-wrap: wrap;
}
.Sitemap-copy{
    display: flex;
    width: auto;
    height: 100px;
    background-color: #141414;
    color: #6c757d;
    align-items: center;
    justify-content: space-around;
    flex-flow: row;

}
.Sitemap-navigation-link-container{
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 300px;
}
.Sitemap-navigation-link-container ul,.Sitemap-address-text{
    font-size: 20px;
    color: #6c757d;
    width: 300px;
    float: left;
    text-align: left;
    display: flex;
    list-style: none;
    flex-direction: column;

}
.Sitemap-navigation-link-container li{
    cursor: pointer;
    margin: 3px;
}
.Sitemap-contact-container{
    display: flex;
    flex-direction: column;
    color: #fff;
    height: 300px;

}

.Sitemap-address-icon{
    width: 30px;
    height: 30px;
    margin-right: 15px;
}
.Sitemap-address-container{
    display: flex;
    margin-bottom: 25px;
    flex-flow: row;
    
}

@media screen and (max-width:850px){
    .Sitemap-container{
        display: flex;
        width: 100%;
        height: auto;
        box-sizing: border-box;
        flex-direction: column;
    }
    .Sitemap-wrapper{
        position: relative;
        height: 100%;
        flex-flow: column;
        justify-content: center;
        padding: 0;
        box-sizing: border-box;
        margin: 0;

    }
    .Sitemap-navigation-link-container ul,.Sitemap-address-text{
        font-size: 16px;
        height: 60px;
    }
    .Sitemap-navigation-link-container h2{
        margin: 0;

    }
    .Sitemap-navigation-link-container{
        display: flex;
        height: 250px;
    }
    .Sitemap-copy{
        
        height: 70px;
        text-align: center;
        font-size: 14px;
        
    }
    .Sitemap-address-text{
        height: 30px;
    }
    .Sitemap-contact-container h1{
        margin: 0;
    }
    .Sitemap-contact-container{
        height: 200px;
    }
    .Sitemap-container{
        height: 850px;
        justify-content: center;
        flex-direction: column;
        display: flex;
        padding: 0;
    }

}
.Service-card-container{
    
    display: flex;
    flex-flow: row;
    width: 300px;
    height: 300px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 30px;
    

}
.Service-card-container svg path{
    background-color: #fff;
    
   filter:drop-shadow(rgba(0, 0, 0, 0.35) 0px 5px 15px);
}
.Service-card-hexagon{
    display: flex;
    flex-flow: row;
    width: 400px;
    height: 350px;
    align-items: center;
    justify-content: center;
    margin: 30px;
    filter: drop-shadow(#1cac5c 4px 4px  5px);
    
    

}
.Service-card-shape{
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);


}

.Service-icon-containetr{
    display: flex;
    width:145px ;
    height: 145px;
    border-top-left-radius: 5px;
    border-bottom-left-radius:5px ;
    align-items: center;
    justify-content: center;
    background-color: #1CAC5C;
    margin-left: 3px;

}
.Service-icon{
    width: 100px;
    height: 100px;
    color: #1CAC5C;
    fill: #1CAC5C;
    

    
}
.Home-slogan-container,.Home-slogan{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 2px;
    padding: 2px;
}
.Home-slogan > img{
    margin: 1px;
}
.Home-slogan > p{
    font-weight: 1000;
    color: #1cac5c;
    font-size: 20px;
    margin: 1px;
    font-style: bold;
}
.Home-page-click-btn{
    width: 150px;
    color: #1cac5c;
    background-color: #fff;
    font-size: 16px;
    border-color: transparent;
    border-radius: 40px;
    height: 40px;

    
   
    cursor: pointer;
}
.Service-btn{
    display: flex;
    width: 150px;
    color: #1cac5c;
    background-color: #fff;
    font-size: 16px;
    border: 1px solid #1cac5c;
    border-radius: 40px;
    height: 40px;
    text-decoration: none;
    align-items: center;
    justify-content: center;

    
   
    cursor: pointer;
}
.Service-btn:hover{
    background-color: #1cac5c;
    color: #fff;
}
.Service-content-container{

    display: flex;
    flex-direction: column;
    width: 200px;
    margin-left: 5px;
    
}
.Service-title{
    display: flex;
    font-size: 25px;
    font-weight: 500;

}
.Service-content-container .Service-title{
    display: flex;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 3px;
}
.Service-text{
    display: flex;
    font-size: 16px;
    font-weight: 300;
    width: 300px;
    margin: 2px;
    text-align: center;
    font-family: 'Poppins', sans-serif;


}

.Home-section-Two{
    display: flex;
    width: 100%;
    height: 700px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #1cac5d11;
}
.Home-section-wrapper{
    display: flex;
    height: 500px;
    width: 900px;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Service-wrapper-one{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 5px;
   
}
.contact-strip > p{
    margin: 5px;
}
.contact-strip{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: #1CAC5C;
    color: #fff;
    width: 100%;
    height: 150px;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
}

.Home-section-title{
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-weight: 400;
    margin: 5px 10px 5px 10px;
}

@media screen and (max-width:640px) {

    .Home-section-title{
        font-size: 40px;
        text-align: center;
    }
    .Home-slogan-container{
        flex-direction: column;
    }
    .Service-card-container,.Service-card-hexagon{
        width: 250px;
        height: 250px;
        margin: 10px;
        

    }
    .Service-icon-containetr{
        width:100px ;
        height: 95px;

    }
    .Service-icon{
        width: 50px;
        height: 50px;
    }
    .Service-text{
        font-size: 14px;
        margin: 0 0 5px 0;
        width: 80%;
    }
    .Service-title{
        font-size: 20px;
        margin-bottom: 5px;
    }
    .Home-section-wrapper{
        width: 100%;
        flex-direction: column;
        height: 900px;
    }
    .Service-wrapper-one{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .contact-strip{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-align: center;
        height: 100px;

        overflow: none;
    }
    .contact-strip > p{

        margin: 2px;

    }
    .Home-page-click-btn{
        width: 150px;
        color: #1cac5c;
        background-color: #fff;
        font-size: 14px;
        border-color: transparent;
        border-radius: 40px;
        height: 30px;
    
        
       
        cursor: pointer;
    }
    .Home-section-Two{
        height: 1200px;
    }

    
}

.whatsapp-btn-container{
    display: block;
    position: fixed;
    width: 70px;
    height: 70px;
    left: 80%;
    top: 80%;
    
    
    filter:drop-shadow(rgb(38, 57, 77) 0px 20px 30px -10px);
    
}

.whatsapp-btn-container > a{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.whatsapp-btn{
    display: flex;
    background-color: #25d366;
    width: 70px;
    height: 70px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    font-size: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
    animation: glowing 1300ms infinite;
    
  
    
    

}
@keyframes glowing {
    0% {
      background-color: #25d366;
      box-shadow: 0 0 3px #25d366;
    }
    50% {
      background-color: #21e907;
      box-shadow: 0 0 15px #21e907;
    }
    100% {
      background-color: #25d366;
      box-shadow: 0 0 3px #25d366;
    }
  }

@media screen and (max-width:640px){
    .whatsapp-btn-container{
        width: 60px;
    height: 60px;
    left: 70%;

    }
    .whatsapp-btn{
        width: 60px;
    height: 60px;

    }


}
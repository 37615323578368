a{  
  text-decoration: none;
  color: inherit;
  
}

html{
  scroll-behavior: smooth;
}
.App{

  overflow: hidden;
}


.About_Us_Container{
    display: flex;
    width: 99.7%;
    height: auto;
    flex-flow: column;

}

.About_Us_Wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 5px;
    box-sizing: border-box;
    overflow-x: none;
    margin-left: 2px;


}

.Cont-card{
        display: flex;
        font-size: 25px;
        border: none;
        justify-content: left;
        height: fit-content;
        padding: 10px;
        align-items: center;

        
       
    
    }

    @media screen and (max-width:640px) {

        .Cont-card{
            display: flex;
            font-size: 14px;
        height: 440px;
        text-align: justify;

        }
        .About-desciption{
            display: flex;
            height: fit-content;
        }
        
        .About_Us_Wrapper{
            display: flex;
            height: 1400px;

        }
        

    }
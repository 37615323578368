.Con_page_container{
    display: flex;
    width: 99.7%;
    height: auto;
    flex-flow: column;

    
}
.Con_Img_Container{
    display: flex;
    height: 100%;
    width: 100%;
    
    align-items: center;
    justify-content: center;
}
.Con-image{
    display: flex;
    height: 100%;
    width: 99.99%;
    object-fit: cover;
    overflow-x: hidden;

}
.diamond_bullet_point > svg{
    fill: #1c5aac;

}
.diamond_bullet_point{
    height: 20px;
    width: 20px;
    
    
    margin-right: 20px;
}
.arrow-icon{
    display: block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
}
.UG-con-container{
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 5px;
    box-sizing: border-box;
    overflow-x: none;
    margin-left: 2px;
}

.Con-card{
    display: flex;
    height: 60px;
    width: 90%;
    margin: 5px;
    color: #000;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
   
    font-size: 25px;
    padding-left: 15px;
    border: 2px solid #1cac5c;
    font-family: 'Poppins', sans-serif;
    
   
}
#Con-gen-info-title{
    background-color: #1c5aac;
    border: 2px solid #1c5aac;
   

}
#Con-gen-info{
/*     
    border: 2px solid #1c5aac; */
    font-size: 25px;
    border: none;
    justify-content: left;
    
    
   

}
.Con-card-title{
    display: flex;
    height: 60px;
    width: 90%;
    margin: 5px;
    color: #fff;
    align-items: center;
    justify-content: center;
   
    font-size: 30px;
    padding-left: 15px;
    background-color: #1cac5c;
    border: 2px solid #1cac5c;
   
}
@media screen and (max-width:640px) {
    .Con_Img_Container{
        height:300px;
    }
    .Con-image{
        height: 300px;
    }
    .Con_page_container{
        display: flex;
        width: 99%;
        height: 100vh;
        flex-flow: column;
        overflow-x: hidden;
    }

    .Con-card,.Con-card-title,#Con-gen-info,#About-info{
        font-size: 18px;
        height: 100px;
    }
    .UG-con-container{
        min-width: 375px;
        
    }
    
}
